import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './_store';
import VueLuxon from "vue-luxon";
import './assets/tailwind.css'
import './assets/global.css'
import './assets/notfound.css';
Vue.config.productionTip = false

Vue.use(VueLuxon)

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')