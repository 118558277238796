import config from '../config/config'
import { handleResponse } from '../helpers/httphelper'

export const itemService = {
    getItems,
    getCharacters
}

async function getCharacters(token) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
        body: JSON.stringify({ "JwtToken": token }),
        signal: controller.signal
    }

    const response = await fetch(config.default.BASE_API_URL.concat('/user/characters'), requestOptions)
    return handleResponse(response)
}

async function getItems() {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const response = await fetch(config.default.BASE_API_URL.concat('/shop/items/'), {
        headers: {
            'x-api-key': config.default.BASE_API_KEY,
            'Content-Type': 'application/json'
        }
    })
    const items = await handleResponse(response)
    return items;
}