<template>
    <div class="min-h-screen container mx-auto pb-16 pt-12 lg:pt-24 px-4 md:px-8 xl:px-0">
        <h1 class="text-3xl lg:text-4xl font-bold">{{ $t('buy_item') }}</h1>
        <div class="mt-6 lg:mt-12 lg:px-20">
            <div class="border-t border-gray-300 py-8">
                <div class="grid grid-cols-2 gap-y-4 lg:gap-y-0 lg:grid-cols-5 bg-light rounded-lg py-5 font-semibold text-gray-500 w-full mx-auto">
                    <button class="hover:text-white font-medium focus:outline-none focus:text-white hover:cursor-pointer w-full text-center border-r-2 border-gray-500">EXTRAS</button>
                    <button class="hover:text-white font-medium focus:outline-none focus:text-white hover:cursor-pointer w-full text-center lg:border-r-2 border-gray-500">CONFORT</button>
                    <button class="hover:text-white font-medium focus:outline-none focus:text-white hover:cursor-pointer w-full text-center border-r-2 border-gray-500">IMPROVEMENT</button>
                    <button class="hover:text-white font-medium focus:outline-none focus:text-white hover:cursor-pointer w-full text-center lg:border-r-2 border-gray-500">COMPANION</button>
                    <button class="hover:text-white font-medium focus:outline-none focus:text-white hover:cursor-pointer w-full text-center">APPEARANCE</button>
                </div>
                <div class="bg-light mt-12 rounded-lg p-8 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-7 gap-4">
                    <div v-for="item in paginatedItems" :key="item.vnum" @click="modalCheckout = true;showItemDetails(item)" class="bg-lighter p-4 flex flex-col rounded-lg items-center cursor-pointer">
                        <img :src="`https://itempicker.atlagaming.eu/api/items/icon/${item.vnum}`" class="w-8 h-8">
                        <h1 class="font-medium text-center">{{ item.name }}</h1>
                        <div class="flex items-center flex-col border-t w-full mt-2 py-2 space-y-1 border-gray-300">
                            <img src="../../assets/images/coin.png" class="w-6 h-6">
                            <p class="text-gray-300">{{ item.price }}</p>
                        </div>
                    </div>
                </div>
                <paginate
                    :page-count="pageCount"
                    :click-handler="handlePageClick"
                    :prev-text="'<'"
                    :next-text="'>'"
                    :prev-class="'text-white font-semibold px-2'"
                    :next-class="'text-white font-semibold px-2'"
                    :page-class="'text-white font-semibold px-2'"
                    :active-class="'text-white font-semibold bg-lighter'"
                    :container-class="'flex items-center justify-center space-x-3 mt-8'">
                </paginate>
                <br>
                <div>
                    <div v-if="successMessage" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                    {{ successMessage }}
                    </div>
                    <div v-if="errorMessage" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
                        {{ errorMessage }}
                    </div>
                    <div class="bg-light mt-12 rounded-lg p-8">
                        <h1 class="border-gray-300 border-b pb-3 text-2xl font-medium w-[60%] mb-6">{{ $t('checkout') }}</h1>
                        <div v-if="checkoutItems.length === 0" class="text-gray-500 text-center">{{ $t('no_items_selected') }}</div>
                        <div class="flex flex-col lg:flex-row space-y-4 lg:space-y-0 items-start lg:space-x-8">
                            <div class="h-64 overflow-y-auto scrollbar-lighter2 w-full lg:w-[70%]">
                                <div v-for="(item, index) in checkoutItems" :key="index" class="flex items-center justify-between border-gray-300 py-5">
                                    <p>{{ $t('character') }}: <span class="font-semibold">{{ item.character.name }}</span> </p>
                                    <div class="flex items-center space-x-4 border-r border-gray-300 w-1/2">
                                    <span>{{ index + 1 }}.</span>
                                    <img :src="require(`@/assets/images/coin.png`)" class="w-8 h-8">
                                    <span>{{ item.price }}</span>
                                    </div>
                                    <div class="flex items-center justify-center w-1/2 relative">
                                    <div class="relative">
                                        <img :src="`https://itempicker.atlagaming.eu/api/items/icon/${item.vnum}`" class="p-0 m-0">
                                        <div class="flex items-center justify-center font-medium absolute bottom-0 -right-4">
                                        <span>x{{ item.quantity }}</span>
                                        </div>
                                    </div>
                                    <div class="absolute -top-3 cursor-pointer right-0 font-bold text-gray-300" @click="removeFromCheckout(index)">X</div>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-lighter p-6 w-full lg:w-[30%]">
                                <h1 class="border-gray-300 border-b pb-3 font-medium w-[60%] mb-6">{{ $t('total') }}</h1>
                                <div class="flex items-center space-x-4">
                                    <img src="../../assets/images/coin.png" class="w-8 h-8">
                                    <span class="text-xl font-semibold">{{ calculateTotal() }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="flex my-4">
                            <label class="text-gray-300 cursor-pointer flex space-x-2 items-center">
                                <input type="checkbox" class="form-checkbox cursor-pointer rounded text-lighter bg-transparent border-gray-300 focus:ring-transparent focus:ring-offset-transparent focus:shadow-none focus:outline-none focus:bg-opacity-0" v-model="isChecked">
                                <p>{{ $t('agree_terms') }}</p>
                            </label>
                        </div>
                        <p class="text-red-500 mt-2" v-if="!isChecked">{{ $t('agree_to_terms_message') }}</p>
                        <div v-else class="flex items-center justify-center uppercase">
                            <button @click="handleCheckout" class="px-5 py-4 bg-lighter w-full lg:w-[20%] focus:outline-none rounded hover:bg-opacity-75">{{ $t('buy') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Modal :show="modalCheckout">
            <div v-on:clickaway="() => { modalCheckout = false; dropdownCharacter = false }" class="md:w-2/5 rounded-lg bg-light">
                <div class="flex items-center justify-end space-x-2 text-white pt-2 pr-2">
                    <svg @click="modalCheckout = false; dropdownCharacter = false" width="21" height="21" class="cursor-pointer fill-current w-4 h-4" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z" />
                    </svg>
                </div>
                <div class="pb-8 px-10">
                    <div class="flex items-start space-x-5">
                        <img :src="`https://itempicker.atlagaming.eu/api/items/icon/${selectedItem.vnum}`" class="w-14 h-14">
                        <div>
                            <span class="font-medium">{{ selectedItem.name }}</span>
                            <div class="flex items-center space-x-2 mt-1">
                                <img src="../../assets/images/coin.png" class="w-6 h-6">
                                <span>{{ selectedItem.price * quantity }}</span>
                            </div>
                        </div>
                    </div>
                    <p class="text-gray-400 py-4">{{ selectedItem.description }}</p>
                    <div class="flex items-center space-x-3">
                        <button @click="decreaseQuantity" class="flex items-center hover:bg-opacity-75 focus:outline-none justify-center w-8 h-8 text-xl font-bold rounded-full bg-lighter">-</button>
                        <input type="text" v-model.number="quantity" min="1" max="9" class="focus:outline-none border rounded-lg py-2 w-10 h-8 bg-transparent text-center">
                        <button @click="increaseQuantity" class="flex items-center hover:bg-opacity-75 focus:outline-none justify-center w-8 h-8 text-xl font-bold rounded-full bg-lighter">+</button>
                    </div>
                    <div class="flex items-center mt-6 space-x-5">
                        <div class="w-full py-4 px-6 relative cursor-pointer border border-gray-300 rounded-lg">
                            <div @click="dropdownCharacter = !dropdownCharacter" class="flex items-center justify-between">
                                <span>{{ selectedCharacter ? selectedCharacter.name : $t('choose_character') }}</span>
                                <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M6.00011 7.99994L0.342773 2.34261L2.22944 0.457275L6.00011 4.22927L9.77077 0.457275L11.6574 2.34261L6.00011 7.99994Z" fill="white" />
                                </svg>
                            </div>
                            <div v-if="dropdownCharacter" class="absolute top-16 left-0 w-full bg-light">
                                <div :class="index != 3 && 'border-b'" v-for="(item, index) in characters" :key="index" @click="selectCharacter(item)" class="flex items-start space-x-5 border-gray-300 py-2 hover:bg-lighter px-4">
                                    <img class="w-10" :src="require(`@/assets/images/class/${item.class}.png`)" alt="">
                                    <div class="flex flex-col">
                                        <span class="font-medium">{{ item.name }} Lvl.{{ item.level }}</span>
                                        <span class="text-gray-400 italic">{{ item.name }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button @click="addToCheckout" class="px-5 py-4 bg-lighter w-[30%] focus:outline-none rounded hover:bg-opacity-75">{{ $t('add') }}</button>
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from '@/components/Modal'
import Paginate from 'vuejs-paginate'
import { itemService } from '../../_services'
import { store } from '@/_store'
import { directive as onClickaway } from 'vue-clickaway2';
import config from '../../config/config'

export default {
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        Modal,
        Paginate
    },
    data() {
        return {
            items: [],
            modalCheckout: false,
            dropdownCharacter: false,
            currentPage: 1,
            itemsPerPage: 21,
            selectedItem: {},
            characters: [],
            selectedCharacter: null,
            quantity: 1,
            checkoutItems: [],
            isChecked: false,
            successMessage: "",
            errorMessage: ""
        }
    },
    computed: {
        ...mapState('account', ['coins']),

        paginatedItems() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.items.slice(start, end);
        },
        pageCount() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        }
    },
    methods: {
    ...mapActions('account', ['decrementCoins']),

    updateErrorMessage(errorMessage) {
        this.errorMessage = errorMessage
    },
    clearCheckout() {
        this.checkoutItems = [];
        this.isChecked = false;
    },
    updateSuccessMessage(successMessage) {
        this.successMessage = successMessage;
    },
    handlePageClick(pageNumber) {
        this.currentPage = pageNumber;
    },
    showItemDetails(item) {
        this.selectedItem = item
        this.modalCheckout = true
    },
    selectCharacter(character) {
        this.selectedCharacter = character;
        this.dropdownCharacter = false;
    },
    decreaseQuantity() {
        if (this.quantity > 1)
            this.quantity--;
    },
    increaseQuantity() {
        if (this.quantity < 999)
            this.quantity++;
    },
    addToCheckout() {
        if (!this.selectedCharacter) {
            this.updateErrorMessage("請選擇角色");
            return;
        }
        const itemToAdd = {
            ...this.selectedItem,
            quantity: this.quantity,
            character: this.selectedCharacter
        };
        this.checkoutItems.push(itemToAdd);
        this.modalCheckout = false;
    },
    removeFromCheckout(index) {
        this.checkoutItems.splice(index, 1);
    },
    calculateTotal() {
        let total = 0;
        this.checkoutItems.forEach(item => {
            total += item.price * item.quantity;
        });
        return total;
    },
    async handleCheckout() {
        const items = this.checkoutItems.map(item => ({
            vnum: item.vnum,
            quantity: item.quantity,
            price: item.price * item.quantity
        }));

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
            body: JSON.stringify({
                senderName: "SpaceNos",
                giftsType: 1,
                gifts: items
            }),
        };

        try {
            const response = await fetch(`${config.default.BASE_API_URL}/player/gift/id/${this.checkoutItems[0].character.id}`, requestOptions);

            if (response.ok) {
                this.checkoutItems.forEach(item => {
                    this.decrementCoins(item.price * item.quantity);
                });
                this.clearCheckout();
                this.updateSuccessMessage("The item has been successfully sent!");
            } else {
                const errorText = await response.text();
                this.updateErrorMessage(errorText || "An error has occurred.");
            }
        } catch (error) {
            this.updateErrorMessage("A problem occurred due to network issues or other reasons.");
        }
    }
}
,
    async created() {
        const response = await itemService.getItems();
        this.items = response;
        const response1 = await itemService.getCharacters(store.state.account.user.token);
        this.characters = response1;
    },
    watch: {
        modalCheckout(newVal) {
            if (!newVal)
                this.quantity = 1;
        }
    },
}
</script>