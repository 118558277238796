import Vue from 'vue'
import VueRouter from "vue-router";
import { store } from '@/_store';
import Home from "@/pages/home.vue";
import News from "@/pages/news.vue";
import BuyCoins from "@/pages/shop/buy_coin.vue";
import BuyItems from "@/pages/shop/items.vue";
import NotFound from "@/pages/notfound.vue";
Vue.use(VueRouter);

const routes = [{
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/shop/buy-items",
        name: "BuyItems",
        component: BuyItems,
        meta: { requiresAuth: true } 
    },
    {
        path: "/shop/buy-coins",
        name: "BuyCoins",
        component: BuyCoins,
        meta: { requiresAuth: true } 
    },
    {
        path: "/news",
        name: "News",
        component: News,
    },
    {
        path: "*",
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const isLoggedIn = !!store.state.account.user;

    if (requiresAuth && !isLoggedIn) {
        next('/');
    } else {
        next();
    }
});

export default router