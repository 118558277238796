import config from '../config/config'
import { handleResponse } from '../helpers/httphelper'

export const newsService = {
    getNews
}

async function getNews() {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const response = await fetch(config.default.BASE_API_URL.concat('/news/articles/'), {
        headers: {
            'x-api-key': config.default.BASE_API_KEY,
            'Content-Type': 'application/json'
        }
    })
    const news = await handleResponse(response)
    return news;
}