import config from '../config/config'
import { store } from '@/_store';
import { handleResponse } from '../helpers/httphelper'

export const userService = {
    login,
    logout,
    getLogin,
    register,
    loginToken
};

function getLogin() {
    let user = localStorage.getItem('user')
    return user ? user : null
}

async function register(user) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
        body: JSON.stringify({ "Username": user.username, "Password": user.password, "Email": user.email, "HcaptchaToken": user.token }),
        signal: controller.signal
    }

    const response = await fetch(config.default.BASE_API_URL.concat('/user/register'), requestOptions)
    return handleResponse(response)
}

async function login(Name, password) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
        body: JSON.stringify({ "Username": Name, "Password": password }),
        signal: controller.signal
    }

    const response = await fetch(config.default.BASE_API_URL.concat('/user/login/'), requestOptions)
    const user = await handleResponse(response)
    if (user.token) {
        localStorage.setItem('user', user.token)
        store.commit('account/loginSuccess', user);
    }
    if (!user.token) {
        store.commit('account/loginFailure', user);
    }
    return user
}

async function loginToken(jtwToken) {
    const controller = new AbortController()
    setTimeout(() => controller.abort(), 2000)

    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
        body: JSON.stringify({ "JwtToken": jtwToken }),
        signal: controller.signal
    }

    const response = await fetch(config.default.BASE_API_URL.concat('/user/loginToken/'), requestOptions)
    const user = await handleResponse(response)
    if (user.token) {
        localStorage.setItem('user', user.token)
        store.commit('account/loginSuccess', user);
    }
    if (!user.token) {
        store.commit('account/loginFailure', user);
    }
    return user
}

function logout() {
    localStorage.removeItem('user')
    store.commit('account/logout');
}