<template>
  <div class="font-Montserrat bg-dark text-white overflow-x-hidden">
    <div class="bg-light">
      <div
        class="
          container
          mx-auto
          flex
          items-center
          justify-between
          py-6
          px-4
          lg:px-0
        "
      >
        <div class="flex items-center space-x-[55px]">
          <router-link exact="" to="/">
            <img src="@/assets/images/logo.png" class="w-[150px]" />
          </router-link>
          <ul class="hidden lg:flex items-center justify-center space-x-7">
            <router-link
              exact=""
              to="/"
              class="
                text-gray-300
                hover:text-white
                hover:border-white
                border-b border-transparent
                cursor-pointer
                mt-3
                pb-2
                font-medium
                uppercase
              "
              >{{ $t("home") }}</router-link
            >
            <div class="h-[25px] w-0 border-r-[1px] border-gray-300"></div>
            <router-link
              to="/news"
              class="
                text-gray-300
                hover:text-white
                hover:border-white
                border-b border-transparent
                cursor-pointer
                mt-3
                pb-2
                font-medium
                uppercase
              "
              >{{ $t("news") }}</router-link
            >
            <div class="h-[25px] w-0 border-r-[1px] border-gray-300"></div>
            <li class="text-gray-300 relative">
              <div
                @click="shopIsOpen = !shopIsOpen"
                class="
                  hover:text-white
                  flex
                  items-center
                  justify-center
                  space-x-3
                  group
                  hover:border-white
                  border-b border-transparent
                  cursor-pointer
                  mt-3
                  pb-2
                  font-medium
                  uppercase
                "
              >
                <span>{{ $t("shop") }}</span>
                <svg
                  :class="shopIsOpen && 'transform rotate-180 '"
                  class="
                    fill-current
                    transition
                    text-gray-300
                    group-hover:text-white
                  "
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.00011 7.99994L0.342773 2.34261L2.22944 0.457275L6.00011 4.22927L9.77077 0.457275L11.6574 2.34261L6.00011 7.99994Z"
                  />
                </svg>
              </div>
              <div
                v-on-clickaway="
                  () => {
                    shopIsOpen = false;
                  }
                "
                v-if="shopIsOpen"
                class="
                  absolute
                  z-10
                  top-[4.5rem]
                  -right-12
                  border-gray-300 border-t
                  w-40
                  h-auto
                  bg-light
                  flex flex-col
                  rounded-b-lg
                  overflow-hidden
                "
              >
                <router-link
                  to="/shop/buy-coins"
                  class="
                    flex
                    items-center
                    justify-center
                    pt-4
                    hover:bg-lighter
                    hover:text-white
                    cursor-pointer
                  "
                >
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      font-medium
                      pb-4
                      border-gray-300 border-b
                      uppercase
                    "
                  >
                    <span>{{ $t("buy_coins") }}</span>
                  </div>
                </router-link>
                <router-link
                  to="/shop/buy-items"
                  class="
                    flex
                    items-center
                    justify-center
                    pt-4
                    hover:bg-lighter
                    hover:text-white
                    cursor-pointer
                  "
                >
                  <div
                    class="
                      flex
                      items-center
                      space-x-2
                      font-medium
                      pb-4
                      uppercase
                    "
                  >
                    <span>{{ $t("items_shop") }}</span>
                  </div>
                </router-link>
              </div>
            </li>
            <div class="h-[25px] w-0 border-r-[1px] border-gray-300"></div>
            <li
              class="
                text-gray-300
                hover:text-white
                hover:border-white
                border-b border-transparent
                cursor-pointer
                mt-3
                pb-2
                font-medium
                uppercase
              "
            >
            <a href="https://discord.gg/7Pq44HRqzR">{{ $t("forum") }}</a>
            </li>
          </ul>
        </div>
        <div
          :class="sideBar ? 'z-10' : 'z-[-1]'"
          class="
            fixed
            w-screen
            h-screen
            lg:z-[-1]
            transition-all
            duration-1000
            ease-in-out
            inset-0
            bg-black bg-opacity-50
          "
        >
          <transition name="slide">
            <div
              v-if="sideBar"
              v-on-clickaway="
                () => {
                  sideBar = false;
                }
              "
              :class="sideBar ? 'z-10' : 'z-[-1]'"
              class="bg-light relative px-6 py-8 h-full w-56 ml-auto"
            >
              <div>
                <img src="../assets/images/logo-2.png" class="" />
              </div>
              <ul class="mt-6 flex flex-col space-y-2 font-semibold">
                <router-link exact="" to="/" class="cursor-pointer">{{
                  $t("home")
                }}</router-link>
                <router-link to="/news" class="cursor-pointer">{{
                  $t("news")
                }}</router-link>
                <li class="group">
                  <div class="flex items-center justify-between cursor-pointer">
                    <h1>{{ $t("shop") }}</h1>
                    <svg
                      class="
                        fill-current
                        transform
                        group-hover:rotate-180
                        transition
                        text-gray-300
                        group-hover:text-white
                      "
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.00011 7.99994L0.342773 2.34261L2.22944 0.457275L6.00011 4.22927L9.77077 0.457275L11.6574 2.34261L6.00011 7.99994Z"
                      />
                    </svg>
                  </div>
                  <ul
                    class="
                      group-hover:py-2
                      ml-4
                      transition-all
                      max-h-0
                      h-auto
                      group-hover:max-h-40
                      overflow-hidden
                      flex flex-col
                      space-y-2
                      uppercase
                    "
                  >
                    <router-link to="/shop/buy-coins" class="cursor-pointer">{{
                      $t("buy_coins")
                    }}</router-link>
                    <router-link to="/shop/buy-items" class="cursor-pointer">{{
                      $t("items_shop")
                    }}</router-link>
                  </ul>
                </li>
                <li class="cursor-pointer uppercase">{{ $t("forum") }}</li>
                <li
                  v-if="!status.loggedIn"
                  @click="modalLogin = true"
                  class="cursor-pointer uppercase"
                >
                  {{ $t("login") }}
                </li>
                <li
                  v-if="!status.loggedIn"
                  @click="modalRegister = true"
                  class="cursor-pointer uppercase"
                >
                  {{ $t("register") }}
                </li>
                <router-link
                  v-if="status.loggedIn"
                  to="/user-setting"
                  class="flex items-center space-x-3 p-0 cursor-pointer"
                >
                  <img src="@/assets/images/avatar.png" class="w-10 -mt-1" />
                </router-link>
              </ul>
            </div>
          </transition>
        </div>
        <div class="flex items-center justify-center space-x-5 uppercase">
          <button
            v-if="!status.loggedIn"
            @click="modalLogin = true"
            class="
              hidden
              lg:block
              px-5
              py-2
              bg-lighter
              focus:outline-none
              rounded
              hover:bg-opacity-75
            "
          >
            {{ $t("login") }}
          </button>
          <button
            v-if="!status.loggedIn"
            @click="modalRegister = true"
            class="
              hidden
              lg:block
              px-5
              py-2
              bg-lighter
              focus:outline-none
              rounded
              hover:bg-opacity-75
            "
          >
            {{ $t("register") }}
          </button>
          <div
            v-if="status.loggedIn"
            class="flex items-center space-x-3 p-0 m-0"
          >
            <button v-on:click="handleLogout" @click="modalLogin = false, modalRegister = false" class="font-semibold">{{ $t("disconnect") }}</button>
          </div>
          <div
            v-if="status.loggedIn"
            class="flex items-center space-x-3 p-0 m-0"
          >
            <span class="font-semibold">{{ coins }}</span>
            <img
              src="../assets/images/coin.png"
              width="35px"
              height="35px"
              alt="coins"
            />
          </div>
          <router-link
            to="/user-setting"
            v-if="status.loggedIn"
            class="flex items-center space-x-3 p-0 cursor-pointer"
          >
            <span class="font-semibold">{{ userName }}</span>
            <img src="@/assets/images/avatar.png" class="w-10 -mt-1" />
          </router-link>
          
          <svg
            @click="sideBar = true"
            class="lg:hidden cursor-pointer"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.2878 10.5874H3.28784"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.2878 6.5874H3.28784"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.2878 14.5874H3.28784"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.2878 18.5874H3.28784"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>

    <router-view></router-view>

    <div class="bg-light">
      <div
        class="
          container
          mx-auto
          py-14
          flex flex-col
          items-center
          justify-center
          space-y-10
          px-4
          md:px-8
          lg:px-0
        "
      >
        <img src="@/assets/images/logo-2.png" class="w-1/2 lg:w-96" />
        <ul
          class="
            grid grid-cols-3
            lg:grid-cols-6
            gap-y-2
            lg:gap-y-0
            border-b
            pb-5
            border-gray-400
            uppercase
            text-sm
            w-full
            lg:w-2/3
            xl:w-1/2
            text-center
          "
        >
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              border-r
              w-full
              px-2
            "
          >
            {{ $t("about_us") }}
          </li>
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              border-r
              w-full
              px-2
            "
          >
            {{ $t("cookie") }}
          </li>
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              lg:border-r
              w-full
              px-2
            "
          >
            {{ $t("policy") }}
          </li>
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              border-r
              w-full
              px-2
            "
          >
            {{ $t("security") }}
          </li>
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              border-r
              w-full
              px-2
            "
          >
            {{ $t("legal") }}
          </li>
          <li
            class="
              text-xs
              md:text-base
              text-gray-300
              hover:text-white
              cursor-pointer
              font-medium
              w-full
              px-2
            "
          >
            {{ $t("contact_us") }}
          </li>
        </ul>
        <div class="grid grid-cols-3 gap-y-2 lg:gap-y-0 lg:grid-cols-6 gap-x-8">
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/instagram.svg"
          />
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/youtube.svg"
          />
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/twitter.svg"
          />
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/facebook.svg"
          />
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/discord.svg"
          />
          <img
            class="w-10 cursor-pointer"
            src="@/assets/images/social-media/epvp.svg"
          />
        </div>
        <p class="text-center text-gray-300 text-xs font-medium uppercase">
          ©2024 SpaceNos. {{ $t("all_right_reserved") }}
        </p>
      </div>
    </div>
    <Modal :show="modalNewPassowrd && !status.loggedIn">
      <div
        v-on-clickaway="
          () => {
            modalNewPassowrd = false;
          }
        "
        class="w-[90%] md:[75%] lg:w-1/2 xl:w-1/4 rounded-lg bg-light"
      >
        <div
          class="flex items-center justify-end space-x-2 text-white pt-2 pr-2"
        >
          <svg
            @click="modalNewPassowrd = false"
            width="21"
            height="21"
            class="cursor-pointer fill-current w-4 h-4"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z"
            />
          </svg>
        </div>
        <div class="py-6 px-10">
          <div
            class="
              text-center
              font-semibold
              border-b
              pb-4
              text-2xl
              border-gray-300
              uppercase
            "
          >
            <h1>{{ $t("new_password") }}</h1>
          </div>
          <div class="mt-6 flex flex-col space-y-4">
            <Input label="New password" type="password" />
            <Input label="Confirm new password" type="password" />
          </div>
          <div class="flex flex-col mt-8 space-y-4 uppercase">
            <button
              @click="modalNewPassowrd = false"
              class="
                px-5
                py-2
                bg-lighter
                focus:outline-none
                rounded
                hover:bg-opacity-75
              "
            >
              {{ $t("confirm_code") }}
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal :show="modalResetPassword && !status.loggedIn">
      <div
        v-on-clickaway="
          () => {
            modalResetPassword = false;
          }
        "
        class="w-[90%] md:[75%] lg:w-1/2 xl:w-1/4 rounded-lg bg-light"
      >
        <div
          class="flex items-center justify-end space-x-2 text-white pt-2 pr-2"
        >
          <svg
            @click="modalResetPassword = false"
            width="21"
            height="21"
            class="cursor-pointer fill-current w-4 h-4"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z"
            />
          </svg>
        </div>
        <div class="py-6 px-10">
          <div
            class="
              text-center
              font-semibold
              border-b
              pb-4
              text-2xl
              border-gray-300
              uppercase
            "
          >
            <h1>{{ $t("reset_password") }}</h1>
          </div>
          <div class="mt-6 flex flex-col space-y-4">
            <Input label="Account" type="text" />
            <Input label="Email" type="email" />
          </div>
          <div class="flex flex-col mt-8 space-y-4">
            <button
              @click="
                modalResetPassword = false;
                modalSecurity = true;
              "
              class="
                px-5
                py-2
                bg-lighter
                focus:outline-none
                rounded
                hover:bg-opacity-75
              "
            >
              {{ $t("next") }}
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal :show="modalSecurity && !status.loggedIn">
      <div
        v-on-clickaway="
          () => {
            modalSecurity = false;
          }
        "
        class="w-[90%] md:[75%] lg:w-1/2 xl:w-1/4 rounded-lg bg-light"
      >
        <div
          class="flex items-center justify-end space-x-2 text-white pt-2 pr-2"
        >
          <svg
            @click="modalSecurity = false"
            width="21"
            height="21"
            class="cursor-pointer fill-current w-4 h-4"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z"
            />
          </svg>
        </div>
        <div class="py-6 px-10">
          <div
            class="
              text-center
              font-semibold
              border-b
              pb-4
              text-2xl
              border-gray-300
              uppercase
            "
          >
            <h1>{{ $t("reset_password") }}</h1>
          </div>
          <div class="mt-6 flex flex-col space-y-4">
            <Input label="Security Code" type="text" />
          </div>
          <div class="flex flex-col mt-8 space-y-4 uppercase">
            <button
              @click="
                modalSecurity = false;
                modalNewPassowrd = true;
              "
              class="
                px-5
                py-2
                bg-lighter
                focus:outline-none
                rounded
                hover:bg-opacity-75
              "
            >
              {{ $t("confirm_code") }}
            </button>
          </div>
        </div>
      </div>
    </Modal>
    <Modal :show="modalLogin && !status.loggedIn">
      <div
        v-on-clickaway="
          () => {
            modalLogin = false;
          }
        "
        class="w-[90%] md:[75%] lg:w-1/2 xl:w-1/4 rounded-lg bg-light"
      >
        <div
          class="flex items-center justify-end space-x-2 text-white pt-2 pr-2"
        >
          <svg
            @click="modalLogin = false"
            width="21"
            height="21"
            class="cursor-pointer fill-current w-4 h-4"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z"
            />
          </svg>
        </div>
        <div class="py-6 px-10">
          <div
            class="
              text-center
              font-semibold
              border-b
              pb-4
              text-2xl
              border-gray-300
              uppercase
            "
          >
            <h1>{{ $t("login") }}</h1>
          </div>
          <form
            @submit.prevent="handleSubmit"
            :model="validateForm"
            ref="validateForm"
          >
            <div class="mt-6 flex flex-col space-y-4">
              <div v-if="alert.message" :class="`${alert.type}`">
                <span class="block sm:inline">{{ alert.message }}</span>
              </div>
              <Input
                v-model="validateForm.loginUsername"
                label="Account"
                type="text"
              />
              <Input
                v-model="validateForm.loginPassword"
                label="Password"
                type="password"
              />
              <div class="flex items-center justify-end">
                <span
                  @click="
                    modalLogin = false;
                    modalResetPassword = true;
                  "
                  class="hover:underline cursor-pointer text-sm text-gray-300"
                  >{{ $t("forgot_password") }}</span
                >
              </div>
            </div>
            <div class="flex flex-col mt-8 space-y-4">
              <div class="flex">
                <label
                  class="
                    text-gray-300
                    cursor-pointer
                    flex
                    space-x-2
                    items-center
                  "
                >
                </label>
              </div>
              <button
                class="
                  px-5
                  py-2
                  bg-lighter
                  focus:outline-none
                  rounded
                  hover:bg-opacity-75
                "
                :disabled="status.loggedIn"
              >
                {{ $t("login") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
    <Modal :show="modalRegister && !status.loggedIn">
      <div
        v-on-clickaway="
          () => {
            modalRegister = false;
          }
        "
        class="w-[90%] md:[75%] lg:w-1/2 xl:w-1/4 rounded-lg bg-light"
      >
        <div
          class="flex items-center justify-end space-x-2 text-white pt-2 pr-2"
        >
          <svg
            @click="modalRegister = false"
            width="21"
            height="21"
            class="cursor-pointer fill-current w-4 h-4"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.4589 11.0791L17.2381 5.23668C17.6277 4.8428 17.6277 4.25199 17.2381 3.85812C16.8485 3.46425 16.2641 3.46425 15.8744 3.85812L10.0952 9.70057L4.316 3.79248C3.92639 3.3986 3.34197 3.3986 2.95236 3.79248C2.56275 4.18635 2.56275 4.77716 2.95236 5.17103L8.73158 11.0135L2.95236 16.8559C2.56275 17.2498 2.56275 17.8406 2.95236 18.2345C3.14717 18.4314 3.40691 18.4971 3.66665 18.4971C3.92639 18.4971 4.18613 18.4314 4.38094 18.2345L10.1602 12.392L15.9394 18.2345C16.1342 18.4314 16.3939 18.4971 16.6537 18.4971C16.9134 18.4971 17.1731 18.4314 17.3679 18.2345C17.7576 17.8406 17.7576 17.2498 17.3679 16.8559L11.4589 11.0791Z"
            />
          </svg>
        </div>
        <div class="py-6 px-10">
          <div
            class="
              text-center
              font-semibold
              border-b
              pb-4
              text-2xl
              border-gray-300
              uppercase
            "
          >
            <h1>{{ $t("register") }}</h1>
          </div>
          <form
            @submit.prevent="handleRegisterSubmit"
            :model="registerForm"
            rel="registerForm"
          >
            <div class="mt-6 flex flex-col space-y-4">
              <div v-if="alert.message" :class="`${alert.type}`">
                <span class="block sm:inline">{{ alert.message }}</span>
              </div>
              <Input
                v-model="registerForm.registerUsername"
                label="Account"
                type="text"
              />
              <Input
                v-model="registerForm.registerPassword"
                label="Password"
                type="password"
              />
              <Input
                v-model="registerForm.registerConfirmPassword"
                label="Confirm password"
                type="password"
              />
              <Input v-model="registerForm.email" label="Email" type="email" />
            </div>
            <div class="flex flex-col mt-8 space-y-4">
              <div class="flex">
                <label
                  class="
                    text-gray-300
                    cursor-pointer
                    flex
                    space-x-2
                    items-center
                  "
                >
                  <input
                    v-model="registerForm.registerRules"
                    type="checkbox"
                    class="
                      form-checkbox
                      cursor-pointer
                      rounded
                      text-lighter
                      bg-transparent
                      border-gray-300
                      focus:ring-transparent focus:ring-offset-transparent
                      focus:shadow-none
                      focus:outline-none
                      focus:bg-opacity-0
                    "
                  />
                  <p>{{ $t("agree_terms") }}</p>
                </label>
              </div>
              <Captcha @send-token="handleTokenMessage" :sitekey="hcaptchakey" />
              <button
                class="
                  px-5
                  py-2
                  bg-lighter
                  focus:outline-none
                  rounded
                  hover:bg-opacity-75
                "
              >
                {{ $t("sign_up") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway2";
import Modal from "../components/Modal";
import Input from "../components/Input";
import Captcha from "../components/Captcha.vue";
import config from "../config/config";
import { mapState, mapActions } from "vuex";
import { Marquee, Slide } from "vue-marquee-component";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  components: {
    Modal,
    Input,
    Captcha,
    [Marquee.name]: Marquee,
    [Slide.name]: Slide,
  },
  data() {
    return {
      hcaptchakey: config.default.CAPTCHA_PUBLIC_KEY,
      hcatpchatoken: '',
      registerForm: {
        registerUsername: "",
        registerPassword: "",
        registerConfirmPassword: "",
        email: "",
        registerRules: false
      },
      validateForm: {
        loginUsername: "",
        loginPassword: ""
      },
      sideBar: true,
      modalLogin: false,
      modalRegister: false,
      modalResetPassword: false,
      modalSecurity: false,
      modalNewPassowrd: false,
      flagActive: {
        img: "uk.svg",
        label: "English",
      },
      shopIsOpen: false,
      flagIsOpen: false,
      submitted: false,
      // todo : add i18n value here
      flags: [
        {
          img: "uk.svg",
          label: "English",
          i18n: "en",
        }
      ],
    };
  },
  computed: {
    ...mapState("account", ["status", "coins"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
    userName() {
      const name = localStorage.getItem('name');
      return name ? JSON.parse(name) : 'Account';
    }
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("account", ["login", "register", "logout"]),
    async handleTokenMessage(event) {
      this.hcatpchatoken = event;
    },
    async handleLogout() {
      await this.logout();
    },
    async handleSubmit() {
      this.submitted = true;
      if (this.validateForm.loginUsername && this.validateForm.loginPassword) {
        let username = this.validateForm.loginUsername;
        let password = this.validateForm.loginPassword
        await this.login({ username, password });
      }
    },
    async handleRegisterSubmit() {
      this.submitted = true;

      if (this.registerForm.registerUsername && this.registerForm.registerPassword && this.registerForm.registerConfirmPassword
          && this.registerForm.email && this.registerForm.registerPassword === this.registerForm.registerConfirmPassword && this.registerForm.registerRules && this.hcatpchatoken)
      {
        let username = this.registerForm.registerUsername;
        let password = this.registerForm.registerPassword;
        let email = this.registerForm.email;
        let token = this.hcatpchatoken
        await this.register({ username, password, email, token });
      }
    },
  },
  watch: {
    $route() {
      this.sideBar = false;
      this.clearAlert();
    },
  },
};
</script>

<style>
.hero {
  background-image: url("../assets/images/background.png");
}
.router-link-active {
  color: white !important;
  border-color: white !important;
}
.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
</style>
