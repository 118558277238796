import Vue from 'vue';
import Vuex from 'vuex';
import { account } from './account.module';
import { alert } from './alert.module';
import vuexI18n from 'vuex-i18n';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        alert,
        account
    }
})

store.dispatch('account/initialize');

Vue.use(vuexI18n.plugin, store, {
    moduleName: 'i18n',
    onTranslationNotFound(locale, key) {
        console.warn(`i18n :: Key '${key} not found for locale '${locale}''`)
    }
});

// TODO : Add others language
Vue.i18n.add('en', require('../i18n/en.json'));
Vue.i18n.add('ita', require('../i18n/ita.json'));
Vue.i18n.add('es', require('../i18n/es.json'));
Vue.i18n.add('fr', require('../i18n/fr.json'));
Vue.i18n.add('de', require('../i18n/de.json'));
Vue.i18n.add('tr', require('../i18n/tr.json'));
Vue.i18n.add('pl', require('../i18n/pl.json'));

Vue.i18n.set('en');

Vue.i18n.fallback('en');