<template>
    <div class="container mx-auto pb-16 pt-12 lg:pt-24 px-4 md:px-8 xl:px-0">
        <h1 class="text-3xl lg:text-4xl font-bold">{{ $t('buy_coins') }}</h1>
        <div class="mt-6 lg:mt-12 lg:px-20">
            <div class=" border-t border-gray-300 pb-8">
                <div v-if="successMessage" class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
                    {{ successMessage }}
                    </div>
                <div class="flex flex-col lg:flex-row items-start lg:space-x-5">
                    <div class="bg-light mt-12 rounded-lg p-8 w-full lg:w-[70%] flex flex-col space-y-8">
                        <div v-for="(item, index) in coins" :key="index" class="flex items-center justify-between space-x-5">
                            <div class="flex items-center justify-between w-[60%] lg:w-[80%] xl:w-[85%] border-r border-gray-300 pr-2 sm:pr-8">
                                <div class="flex items-center space-x-3">
                                    <img src="../../assets/images/logo.png" style="width: 25px;">
                                    <h1 class="text-sm sm:text-lg font-medium">{{ item.amount }}</h1>
                                </div>
                                <h1 class="text-sm sm:text-lg font-medium">€{{ item.price }}</h1>
                            </div>
                            <div class="flex items-center space-x-2 justify-between w-[40%] lg:w-[20%] xl:w-[15%]">
                                <button class="flex items-center focus:outline-none justify-center w-6 h-6 sm:w-8 sm:h-8 text-xl font-bold rounded-full bg-lighter" @click="decrement(index)">-</button>
                                <input type="text" class="focus:outline-none border rounded-lg py-2 w-8 sm:w-10 h-8 bg-transparent text-center" :value="quantities[index]" readonly>
                                <button class="flex items-center focus:outline-none justify-center w-6 h-6 sm:w-8 sm:h-8 text-xl font-bold rounded-full bg-lighter" @click="increment(index)">+</button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-light mt-12 rounded-lg p-8 w-full lg:w-[30%]">
                        <h1 class="border-gray-300 border-b pb-3 text-2xl font-medium">{{ $t('checkout') }}</h1>
                        <div class="bg-lighter flex items-center justify-between p-4 mt-8">
                            <div class="flex items-center space-x-3 border-r border-gray-300 w-full">
                                <img src="../../assets/images/logo.png" style="width: 25px;">
                                <h1 class="text-lg font-medium">{{ totalAmount }}</h1>
                            </div>
                            <h1 class="text-lg font-medium pl-8">€{{ totalPrice.toFixed(2) }}</h1>
                        </div>
                        <div class="flex flex-col mt-8 space-y-2">
                            <div class="flex">
                                <label class="text-gray-300 cursor-pointer flex space-x-2 items-center">
                                    <input type="checkbox" class="form-checkbox cursor-pointer rounded text-lighter bg-transparent border-gray-300 focus:ring-transparent focus:ring-offset-transparent focus:shadow-none focus:outline-none focus:bg-opacity-0" v-model="isChecked">
                                    <p>{{ $t('agree_terms') }}</p>
                                </label>
                            </div>
                            <div id="paypal-button-container"></div>
                            <p class="text-red-500 mt-2" v-if="!isChecked">{{ $t('agree_to_terms_message') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { store } from '@/_store';
import { coinService } from '../../_services'
import config from '../../config/config'

export default {
    data() {
        return {
            quantities: [],
            coins: null,
            isChecked: false,
            paypalButtonInstance: null,
            successMessage: ""
        };
    },
    computed: {
        ...mapState('account', ['coins']),

        totalAmount() {
            return this.coins.reduce((sum, coin, index) => sum + coin.amount * this.quantities[index], 0);
        },
        totalPrice() {
            return this.coins.reduce((sum, coin, index) => sum + coin.price * this.quantities[index], 0);
        }
    },
    mounted() {
        this.loadPayPalScript().then(() => {
            if (this.isChecked) {
                this.initializePayPalButton();
            }
        }).catch((error) => {
            console.error('Failed to load PayPal SDK:', error);
        });
        
        for (let i = 0; i < 9; i++)
            this.quantities.push(0);
    },
    watch: {
        isChecked(newVal) {
            if (newVal && !this.paypalButtonInstance) {
                this.initializePayPalButton();
            } else if (!newVal && this.paypalButtonInstance) {
                this.paypalButtonInstance.close();
                this.paypalButtonInstance = null;
            }
        }
    },
    methods: {
        ...mapActions('account', ['incrementCoins']),
        updateSuccessMessage(successMessage) {
            this.successMessage = successMessage;
        },
        increment(index) {
            if (this.quantities[index] < 9)
                this.$set(this.quantities, index, this.quantities[index] + 1);
        },
        decrement(index) {
            if (this.quantities[index] > 0)
                this.$set(this.quantities, index, this.quantities[index] - 1);
        },
        loadPayPalScript() {
            return new Promise((resolve, reject) => {
                if (document.getElementById('paypal-sdk')) {
                    resolve();
                    return;
                }
                const script = document.createElement('script');
                script.id = 'paypal-sdk';
                script.src = 'https://www.paypal.com/sdk/js?client-id=AapHHXeNOq32OEyisFJG21lP4dqtOOl0yn9IcbpYQI0wO2T7sYODC2o2b1ttPr_Lh2bTy_U18883BE2Q&currency=EUR';
                script.onload = () => resolve();
                script.onerror = (err) => reject(err);
                document.head.appendChild(script);
            });
        },
        initializePayPalButton() {
            if (!window.paypal) {
                console.error('PayPal SDK not loaded.');
                return;
            }
            this.paypalButtonInstance = window.paypal.Buttons({
                createOrder: (data, actions) => {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                value: this.totalPrice.toFixed(2)
                            }
                        }],
                        application_context: {
                            shipping_preference: 'NO_SHIPPING'
                        }
                    });
                },
                onApprove: (data, actions) => {
                    return actions.order.capture().then(details => {
                        const purchasedCoins = this.coins.map((coin, index) => {
                            return {
                                coinType: coin.amount,
                                pricePerCoin: coin.price,
                                quantity: this.quantities[index],
                                totalPrice: coin.price * this.quantities[index]
                            };
                        }).filter(item => item.quantity > 0);

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json', 'x-api-key': config.default.BASE_API_KEY },
                            body: JSON.stringify({ "OrderID": details.id, "TransactionDetails": details, "PurchasedCoins": JSON.stringify(purchasedCoins), "JwtToken": store.state.account.user.token }),
                        };

                        fetch(config.default.BASE_API_URL.concat('/shop/paypal-transaction-complete'), requestOptions)
                            .then((response) => {
                                if (response.status === 200) {
                                    this.incrementCoins(this.totalAmount)
                                    this.updateSuccessMessage("Your payment was successful, and your coins have been successfully added to your account. Thank you for supporting SpaceNos!");
                                }
                            })
                    });
                }
            });
            this.paypalButtonInstance.render('#paypal-button-container');
        }
    },
    async created() {
        var x = await coinService.getCoins();
        this.coins = x;
    },
    beforeDestroy() {
        if (this.paypalButtonInstance) {
            this.paypalButtonInstance.close();
            this.paypalButtonInstance = null;
        }
        const script = document.getElementById('paypal-sdk');
        if (script) {
            script.parentNode.removeChild(script);
        }
    }
};
</script>
