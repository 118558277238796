import { userService } from '../_services';

const state = {
    status: {},
    user: localStorage.getItem('user') || null,
    coins: 0
};

const actions = {
    initialize({ dispatch, commit }) {
        dispatch;
        var jwtToken = localStorage.getItem('user')

        console.log(jwtToken)

        userService.loginToken(jwtToken)
            .then(
                user => {
                    commit('loginSuccess', user);
                },
                error => {
                    commit('loginFailure', error);
                }
            )
    },
    login({ dispatch, commit }, { username, password }) {
        commit('loginRequest', { username })

        userService.login(username, password)
            .then(
                user => {
                    commit('loginSuccess', user);
                },
                error => {
                    commit('loginFailure', error);
                    dispatch('alert/error', "Unable to find the account.", { root: true });
                }
            )
    },
    logout({ commit }) {
        userService.logout();
        commit('logout');
    },
    register({ dispatch, commit }, user) {
        commit('registerRequest', user);

        userService.register(user)
            .then(
                user => {
                    commit('registerSuccess', user);
                    setTimeout(() => {
                        dispatch('alert/success', 'Registration successful !', { root: true });
                    })
                },
                error => {
                    commit('registerFailure', error);
                    dispatch('alert/error', 'The account has already been taken !', { root: true });
                }
            )
    },
    incrementCoins({ commit }, amount) {
        commit('incrementCoins', amount);
    },
    decrementCoins({ commit }, amount) {
        commit('decrementCoins', amount);
    }
};

const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.coins = user.coins;
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggedIn: true };
        state.coins = user.coins;
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.coins = 0;
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.coins = 0;
        state.user = null;
    },
    registerRequest(state) {
        state.status = { registering: true };
    },
    registerSuccess(state) {
        state.status = {};
    },
    registerFailure(state) {
        state.status = {};
    },
    incrementCoins(state, amount) {
        state.coins += amount;
    },
    decrementCoins(state, amount) {
        state.coins -= amount;
    }
};

export const account = {
    namespaced: true,
    state,
    actions,
    mutations
}