<template>
    <div class="min-h-screen container mx-auto pb-16 pt-12 lg:pt-24 px-4 md:px-8 xl:px-0">
        <h1 class="text-3xl lg:text-4xl font-bold">Our last news</h1>
        <div class="mt-12 lg:px-20">
            <div v-if="!news">
                <p>Unable to get latest news please try again later</p>
            </div>
            <div v-for="(item,index) in news" :key="index" class=" border-t border-gray-300 py-8">
                <div class="flex flex-col lg:flex-row items-start space-y-8 lg:space-y-0 lg:space-x-8">
                    <div class="w-full rounded-lg overflow-hidden">
                        <img :src="`https://itempicker.atlagaming.eu/api/items/icon/${item.image}`" class="w-full transform transition hover:scale-110">
                    </div>
                    <div class="flex flex-col justify-between">
                        <div>
                            <h1 class="text-3xl lg:text-4xl font-semibold">{{ item.title }}</h1>
                            <p class="text-gray-300 mt-3">{{item.description}}</p>
                        </div>
                        <h3 class="lg:text-xl mt-8">{{ item.date | luxon }}</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { newsService } from '../_services'

export default {
    data(){
        return {
            news: null
        }
    },

    async created() {
        var x = await newsService.getNews()
        this.news = x
    }
}
</script>