<template>
    <div v-if="show" class="fixed z-40 bg-gray-500 bg-opacity-50  inset-0 min-h-screen flex items-center justify-center overflow-y-auto overflow-x-hidden " aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['show'],
    methods: {
        closeModal: function () {
            this.$emit('closeModal');
        }
    },
    // watch: {
    //     show: function (newVal) {
    //         if (newVal == true) {
    //         document.querySelector('body').classList.add('overflow-hidden');
    //         }else{
    //         document.querySelector('body').classList.remove('overflow-hidden');
    //         }
    //     }
    // }
}
</script>