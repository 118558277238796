<template>
    <!-- Fix cannot read property 'context' of undefined -->
     <div class="flex flex-col text-gray-300 w-full">
        <label v-if="label" class="text-sm mb-1">{{ label }}</label>
        <input :type="type" :placeholder="placeholder" :class="classname" v-model="input" class="p-2 border border-gray-300 rounded-lg bg-transparent focus:outline-none">
    </div>
</template>
<script>
export default {
    props: {
        value:
        {
            type: String,
            default: ''
        },
        label:
        {
            type: String,
            default: ''
        },
        placeholder:
        {
            type: String,
            default: ''
        },
        classname: 
        {
            type: String,
            default: ''
        },
        type: 
        {
            type: String,
            default: ''
        }
    },

    data () {
        return {
            input: this.value,
        }
    },

    watch: 
    {
        value (newVal, oldVal) {
            if (newVal !== oldVal) this.input = newVal;
        },
        input(newVal, oldVal) 
        {
            if (newVal !== oldVal) this.$emit('input', newVal);
        }
    }

    //props:['label','placeholder','classname','type'],
}
</script>