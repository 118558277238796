<template>
  <Layout/>
</template>

<script>
import Layout from './layouts/default'

export default {
  name: 'App',
  components: {
    Layout
  }
}
</script>