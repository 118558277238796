<template>
  <vue-hcaptcha
    @verify="onVerify"
    @expired="onExpire"
    :sitekey="sitekey"
    theme="dark"
  ></vue-hcaptcha>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

export default {
  props: {
    sitekey: {
      type: String,
      default: "",
    }
  },

  data() {
    return {
      verified: false,
      callbackToken: null,
      callbackKey: null,
    };
  },

  methods: {
    onVerify(token) {
      this.$emit('send-token', token);
    },
    onExpire() {
      this.verified = false;
      this.callbackToken = null;
      this.callbackKey = null;
    },
  },

  components: { VueHcaptcha },
};
</script>